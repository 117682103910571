<template>
  <VNavigationDrawer v-model="drawer" class="db-gold" app right temporary>
    <BaseButton
      icon
      small
      color="green lighten-1"
      md-icon="close"
      aria-label="Close Menu"
      @click="$store.commit('nav/TOGGLE_DRAWER')"
    ></BaseButton>
    <VList>
      <VListTile to="/" @click.native.stop="currPageAction">
        <VListTileContent>
          <VListTileTitle class="r-title">Home</VListTileTitle>
        </VListTileContent>
      </VListTile>
      <VListGroup v-model="showMenusDropdown" no-action>
        <template #activator>
          <VListTile>
            <VListTileContent>
              <VListTileTitle class="r-title">Menus</VListTileTitle>
            </VListTileContent>
          </VListTile>
        </template>

        <WithLocationSummaries v-slot="{ locations }">
          <div class="nav-drawer-locations">
            <VListTile
              v-for="loc in locations"
              :key="loc.title"
              :to="`/locations/${loc.slug}`"
              @click.native.stop="currPageAction"
            >
              <VListTileContent>
                <VListTileTitle class="r-title">{{ loc.title }}</VListTileTitle>
              </VListTileContent>
            </VListTile>
          </div>
        </WithLocationSummaries>
      </VListGroup>
      <VListTile to="/about" @click.native.stop="currPageAction">
        <VListTileContent>
          <VListTileTitle class="r-title">About</VListTileTitle>
        </VListTileContent>
      </VListTile>

      <VListTile to="/contact" @click.native.stop="currPageAction">
        <VListTileContent>
          <VListTileTitle class="r-title">Contact</VListTileTitle>
        </VListTileContent>
      </VListTile>
      <BaseButtonChowNow
        class="r-title ml-0 mt-3"
        aria-label="Order Online with ChowNow"
        >Order Online</BaseButtonChowNow
      >
      <BaseButton
        href="https://squareup.com/gift/0V5KZPDNKH0NS/order"
        color="db-orange"
        class="r-title ml-0 mt-3"
        aria-label="Order a Daily Beet Gift Card Online"
        >Gift Cards</BaseButton
      >
    </VList>
  </VNavigationDrawer>
</template>

<script>
import WithLocationSummaries from '@dataProviders/WithLocationSummaries'

export default {
  name: 'MobileNavBar',
  components: { WithLocationSummaries },
  data() {
    return {
      showMenusDropdown: false,
    }
  },
  computed: {
    drawer: {
      get() {
        return this.$store.state.nav.isDrawerOpen
      },
      set(val) {
        this.$store.commit('nav/SET_DRAWER', val)
      },
    }, // drawer
  },
  beforeMount() {
    // Set the drawer to closed on load
    this.$store.commit('nav/SET_DRAWER', false)
  },
  methods: {
    currPageAction() {
      this.$store.commit('nav/SET_DRAWER', false)
    }, // currPageAction
  },
}
</script>

<style lang="scss">
@import '@design';

.nav-drawer-locations {
  a.v-list__tile {
    padding-left: 40px;
  }
}
</style>
