<template>
  <div class="toolbar">
    <MobileNavBar />
    <VToolbar
      :class="['header white', `px-${['xs', 'sm'].includes($mq) ? 1 : 4}`]"
      fixed
      app
      :height="$mq === 'xs' ? '70px' : '120px'"
      scroll-off-screen
      :scroll-threshold="200"
    >
      <VFlex class="nav-left hidden-xs-only" grow shrink>
        <nav>
          <span class="nav-route">
            <VMenu open-on-hover bottom offset-y content-class="elevation-2">
              <template #activator="{ on }">
                <a color="transparent" v-on="on">
                  Menus
                </a>
              </template>

              <VList class="db-purple">
                <WithLocationSummaries v-slot="{ locations }">
                  <div class="locations-dropdown">
                    <VListTile
                      v-for="(loc, index) in locations"
                      :key="index"
                      :to="`/locations/${loc.slug}`"
                    >
                      <VListTileTitle class="asap--font white--text">{{
                        loc.title
                      }}</VListTileTitle>
                    </VListTile>
                  </div>
                </WithLocationSummaries>
              </VList>
            </VMenu>
          </span>
          <NavBarRoutes
            :routes="
              persistentNavRoutes.filter(
                (r) => !r.position || r.position !== 'right'
              )
            "
          />
        </nav>
      </VFlex>
      <BaseLink :to="{ path: '/' }">
        <img
          :class="['nav-logo', { small: $mq === 'xs' }]"
          src="@assets/images/db-logo.jpg"
          alt="Daily Beet Logo"
        />
      </BaseLink>
      <VFlex
        class="nav-right"
        grow
        :shrink="!['xs', 'sm'].includes($mq)"
        text-sm-right
      >
        <nav>
          <VLayout row wrap text-xs-right justify-end align-center>
            <VFlex
              v-for="channel of socialChannels"
              :key="channel.name"
              class="nav-route social-icon text-xs-center mx-0 mb-0 mt-1 hidden-sm-and-down"
            >
              <a :href="channel.url" target="_blank" class="py-2 px-2 mr-2">
                <img :src="channel.icon" :alt="channel.name" />
              </a>
            </VFlex>
            <VFlex shrink>
              <BaseButton
                v-if="!['xs', 'sm'].includes($mq)"
                class="mr-0"
                small
                outline
                href="https://squareup.com/gift/0V5KZPDNKH0NS/order"
                aria-label="Order a Daily Beet Gift Card Online"
                title="Order a Daily Beet Gift Card Online"
                target="_blank"
                >Gift Cards</BaseButton
              >
              <BaseButtonChowNow
                small
                :class="[
                  ['md'].includes($mq) ? 'ml-2' : 'ml-3',
                  ['xs'].includes($mq) ? 'mr-4' : 'mr-0',
                ]"
                aria-label="Order Online with ChowNow"
                >Order {{ $mq === 'xs' ? 'Now' : 'Online' }}</BaseButtonChowNow
              >
            </VFlex>
            <!-- <NavBarRoutes
          :routes="persistentNavRoutes.filter((r) => r.position === 'right')"
        /> -->
            <BaseButton
              icon
              flat
              color="transparent"
              text-color="black"
              large
              md-icon="menu"
              aria-label="Toggle Mobile Menu"
              class="hidden-sm-and-up mr-0"
              @click="$store.commit('nav/TOGGLE_DRAWER')"
            ></BaseButton>
          </VLayout>
        </nav>
      </VFlex>
    </VToolbar>
  </div>
</template>

<script>
import { authComputed } from '@store/helpers'
import MobileNavBar from '@components/MobileNavBar'
import NavBarRoutes from '@components/NavBarRoutes'
import WithLocationSummaries from '@dataProviders/WithLocationSummaries'
export default {
  components: { NavBarRoutes, WithLocationSummaries, MobileNavBar },
  data() {
    return {
      persistentNavRoutes: [
        {
          name: 'about',
          title: 'About',
          to: {
            path: '/about',
          },
        },
        {
          name: 'contact',
          title: 'Contact',
          to: {
            path: '/contact',
          },
        },
        {
          name: 'social',
          title: 'Social',
          position: 'right',
        },
        {
          name: 'order-online',
          title: 'Order Online',
          position: 'right',
          tag: 'BaseButtonChowNow',
          class: 'mr-0',
        },
      ],
      menusDropdownItems: [
        { title: 'Magazine', slug: 'magazine' },
        { title: 'St. Roch', slug: 'st-roch' },
        { title: 'CBD', slug: 'tdb-cbd' },
      ],
      socialChannels: [
        {
          name: 'Facebook',
          icon: require('@assets/images/icon-facebook.svg'),
          url: 'https://www.facebook.com/thedailybeetnola',
        },
        {
          name: 'Instagram',
          icon: require('@assets/images/icon-instagram.svg'),
          url: 'https://www.instagram.com/thedailybeetnola/',
        },
      ],
    }
  },
  computed: {
    ...authComputed,
  },
}
</script>

<style lang="scss">
@use "sass:math";
@import '@design';

.toolbar .v-toolbar {
  z-index: 3;
}

.header {
  background-color: $color-navbar;
}
.nav-left,
.nav-right {
  flex-basis: 50%;
}

.nav-route {
  display: inline-block;
  margin: 0 math.div($size-grid-padding, 2);
  text-transform: uppercase;
  a {
    // TODO (ESS) : globalize fonts
    font-family: 'Asap', sans-serif;
    font-weight: 300;
    color: black;
    text-decoration: none;
    letter-spacing: 0.15em;
  }
  &.BaseButton a {
    color: $color-button-text;
  }
  &.social-icon {
    flex: 0 1 auto;
    img {
      transition: opacity 0.2s ease;
    }
    &:hover {
      img {
        opacity: 0.7;
      }
    }
  }
  &.router-link-active a {
    padding-bottom: 0.4em;
    color: $color-link-text;
    text-decoration: none;
    cursor: default;
    border-bottom: 2px solid $color-db-purple;
  }
} // nav-route

.nav-logo {
  max-width: 97px;
  cursor: pointer;
  &.small {
    max-width: 60px;
  }
}

@media only screen and (max-width: 414px) {
  .toolbar .v-toolbar__content {
    padding: 0 5px;
  }
}
</style>
